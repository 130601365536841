import React from "react"
import Layout from "../components/layout"

export default function NotFound() {
  return (
    <Layout>
      <div>404 Not Found</div>
    </Layout>
  )
}
